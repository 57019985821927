const BASE_URL = process.env.VUE_APP_BASE_URL;
var token = localStorage.getItem("token")
import axios from "axios";
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
   Authorization: `Bearer ${token}`
};

export const contactApi = {


  async contact(page) {
    const response = await axios(`${BASE_URL}/contact?page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    })
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async broker(page) {
    const response = await axios(`${BASE_URL}/broker?page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    })
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async getBrokerById(id) {
    const response = await axios(`${BASE_URL}/broker/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  
  async delectBrokerById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${BASE_URL}/deleteBroker/${id}`, {
      method: "DELETE",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async delectContactById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${BASE_URL}/deleteContact/${id}`, {
      method: "DELETE",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async login(user) {
     const response = await fetch(`${BASE_URL}/login`,
      {
        method: "POST",
        headers: {
          ...headers,
        },
        body: JSON.stringify(user)
      }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

};

